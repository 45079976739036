<template>
  <div class="main">
    <div class="banner">
      <img src="../../assets/image/banner_crm.png" alt>
      <div class="banner_tit">
        <h2>CRM</h2>
        <p>市场、售前、售中、售后全生命周期管理，祝您驰骋商海战无不胜.</p>
        <div class="btn">
          <a href>立即咨询</a>
        </div>
      </div>
    </div>
    <div class="kernel">
      <div class="kernels">
        <h2>核心功能</h2>
        <div class="crm_nav">
          <div class="crm_nav_list">
            <li @click='change2(1)' :class='currentIndex2==1?"router_active":""' class="on">客户管理</li>
          </div>
          <div class="crm_nav_list">
            <li @click='change2(2)' :class='currentIndex2==2?"router_active":""' class="on">销售过程管理</li>
          </div>
          <div class="crm_nav_list">
            <li @click='change2(3)' :class='currentIndex2==3?"router_active":""' class="on">订单合同管理</li>
          </div>
          <div class="crm_nav_list">
            <li @click='change2(4)' :class='currentIndex2==4?"router_active":""' class="on">统计分析</li>
          </div>
        </div>
      </div>
      <div class="kernels" v-show="currentIndex2==1">
        <div class="left">
          <h3>客户管理</h3>
          <p>完善的客服资料管理功能，可能客户进行分门别类的管理，方便查询，每个客户的状态、历史跟进情况都一目了然。即使员工离职他的客户资料、跟进情况也都完整的保留下来不会流失，新人很容易接手。当工作基于客户无忧进行，所以一切都有历史记录可查。</p>
          <div class="btn">
            <a href>立即咨询</a>
          </div>
        </div>
        <div class="right">
          <img src="../../assets/image/crm_kernel1.png" alt class="crm_kernel">
        </div>
      </div>
      <div class="kernels" v-show="currentIndex2==2">
        <div class="left">
          <h3>销售过程管理</h3>
          <p>通过销售报价、销售订单、销售发货、退货、销售发票处理、客户管理、价格管理等功能，对销售全过程进行有效的控制和跟踪。可以帮助企业的销售人员完成客户档案管理、销售报价管理、销售订单管理、客户订金管理、客户信用检查、提货单及销售提货处理、销售发票及客户退货、货款拒付处理等一系列销售管理事务。</p>
          <div class="btn">
            <a href>立即咨询</a>
          </div>
        </div>
        <div class="right">
          <img src="../../assets/image/crm_kernel2.png" alt class="crm_kernel">
        </div>
      </div>
      <div class="kernels" v-show="currentIndex2==3">
        <div class="left">
          <h3>订单合同管理</h3>
          <p>业务员可以提交合同签署申请，上级领导审核，通过后签署并保存到系统中作为历史记录，方便日后查询统计。</p>
          <div class="btn">
            <a href>立即咨询</a>
          </div>
        </div>
        <div class="right">
          <img src="../../assets/image/crm_kernel3.png" alt class="crm_kernel">
        </div>
      </div>
      <div class="kernels" v-show="currentIndex2==4">
        <div class="left">
          <h3>统计分析</h3>
          <p>通过强大的统计报表功能，能实时的清晰的查看到指定时间的销售情况、客户分布情况、员工销售业绩等等一切您想要看到的数据。使公司经营状况更加清晰明了。</p>
          <div class="btn">
            <a href>立即咨询</a>
          </div>
        </div>
        <div class="right">
          <img src="../../assets/image/crm_kernel4.png" alt class="crm_kernel">
        </div>
      </div>
    </div>
    <div class="subdivide">
      <h2>细分行业精耕细作</h2>
      <div class="subdivide_list">
        <div class="left">
          <h5 @click='change(1)' :class='currentIndex==1?"on":""'>电子销售行业</h5>
          <h5 @click='change(2)' :class='currentIndex==2?"on":""'>制作业</h5>
          <h5 @click='change(3)' :class='currentIndex==3?"on":""'>租赁行业</h5>
          <h5 @click='change(4)' :class='currentIndex==4?"on":""'>贸易行业</h5>
        </div>
        <div class="right" v-show="currentIndex==1">
          <h3>销售管理</h3>
          <p>系统实现了销售订单预评估功能和配套管理功能，可以快速地提供客户订购产品的成本估算结果、可签约量等信息，对需求的零部件进行配套分析，供业务员接收订单时参考。针对电子产品存在定制需求的特点，七陌CRM完善了产品配置功能。企业根据客户的特定要求，从已定义的标准产品配置清单中选配生成适合客户定制要求的BOM单，结合订单评估功能快速地响应客户并提供信息给物流、计划及生产部门，最终实现满意交付。</p>
          <h3>产品管理</h3>
          <p>完善的工程数据管理可以实现对电子产品结构欧（BOM）的单级、多级正逆向展开查询，以及配置BOM功能，以方便成本核算及生产领发料。多种价格的成本BOM，可以有效地评估产品的成本，供价格决策参考。</p>
        </div>
        <div class="right" v-show="currentIndex==2">
          <h3>客户管理</h3>
          <p>完善的客户数据管理，有效管理重要客户，整合售前与售后信息。</p>
          <h3>项目管理</h3>
          <p>管理项目报备、立项、评审、招投标等业务环节，同时掌握业务员日常工作进度以及项目团队成本，通过报表，可以在复盘项目时由针对性的提出改进方案。</p>
          <h3>售后管理</h3>
          <p>通过工单，来跟进投诉、咨询、维修、保养等服务请求。</p>
        </div>
        <div class="right" v-show="currentIndex==3">
          <h3>整合资源</h3>
          <p>全面的客户联系渠道，综合了咨询、管理和服务等，帮助企业资源与信息高速顺畅的流转，提高企业运营效率，员工技能的提高，意识的增加，服务的完善等</p>
          <h3>防范风险</h3>
          <p>有效的客户关系管理实时采集、整理、汇总和更新来自不同渠道客户的信息，为企业动态决策提供强有力的信息保障；企业通过CRM系统提供的信息共享和交流沟通平台，及时进行经营环节的沟通和交流，一旦某个环节出现问题，就可以通过这个信息平台使企业得知，及时分析、调整出现的问题， 时间防止风险的扩大。</p>
        </div>
        <div class="right" v-show="currentIndex==4">
          <h3>客户资料管理</h3>
          <p>客户是业务开展的重心，也是CRM系统的灵魂，一切业务活动都是围绕客户为中心而开展，为企业准备了详尽的客户资料系统。关联了业务开展过程中与客户相关的所有线索资料和业务跟进状态。</p>
          <h3>报价管理</h3>
          <p>通过内置的价格和条件模型，直接向销售人员提供强大的定价功能，销售代表可以将各种商品的销售信息反馈给上级，为商业策划方案提供依据。</p>
        </div>
      </div>
    </div>
    <div class="ratio">
      <h2>助力互联网企业业绩强劲增长</h2>
      <img src="../../assets/image/crm_ratio.png" alt>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      currentIndex : 1,
      currentIndex2 : 1
    }
    
  },
  methods:{
     change:function(index){
        this.currentIndex=index;
      },
      change2:function(index){
        this.currentIndex2=index;
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main .banner {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.banner img {
  width: 1920px;
}
.banner .banner_tit {
  width: 600px;
  height: 300px;
  position: absolute;
  top: 130px;
  left: 385px;
  text-align: left;
}
.banner .banner_tit h2 {
  font-size: 60px;
  color: #fff;
}
.banner .banner_tit p {
  width: 580px;
  font-size: 24px;
  color: #fff;
  margin-top: 10px;
}
.banner .banner_tit .btn {
  width: 185px;
  height: 55px;
  background: #fff;
  border-radius: 2px;
  font-size: 17px;
  color: #ff0610;
  line-height: 55px;
  text-align: center;
  margin-top: 25px;
}
.kernel {
width: 100%;
  margin-top: 70px;
  margin-bottom: 100px;
}
.kernels{
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.kernel .left {
  width: 600px;
}
.kernels h2 {
  width: 600px;
  height: 62px;
  font-size: 36px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 62px;
  border-bottom: 1px solid #D6E4FF;
text-align: left;
}
.kernels  .crm_nav {
  width: 600px;
  display: flex;
  justify-content: space-between;
  margin-top: 21px;
  border-bottom: 1px solid #D6E4FF;
}
.kernels  .crm_nav .router_active {
  color: #ff0610;
  height: 40px;
  display: inline-block;
  border-bottom: 3px solid #ff0610;
  font-size: 16px;
  font-weight: 600;
}
.kernels .on {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  cursor: pointer;
}
.kernel .left h3 {
  width: 100%;
  height: 40px;
  font-size: 28px;
  font-weight: 600;
  color: #000000;
  line-height: 40px;
  margin-top: 112px;
  margin-left: 40px;
  text-align: left;
}
.kernel .left p {
  width: 468px;
  /* height: 88px; */
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4a4a4a;
  line-height: 22px;
  margin: 25px 0;
  margin-left: 40px;
  text-align: left;
}
.kernel .left .btn {
  width: 139px;
  height: 54px;
  background: #ff0610;
  border-radius: 2px;
  color: #fff;
  line-height: 54px;
  text-align: center;
  font-size: 22px;
  margin-left: 40px;
  margin-top: 25px;
}
.kernel .right {
  width: 600px;
}

.kernel .right .crm_kernel {
  width: 600px;
  height: 370px;
  margin-top: 30px;
}
.subdivide {
  width: 100%;
  background: #F0F4FC;
  margin: 0 auto;
  padding-top: 32px;
  padding-bottom: 40px;
}
.subdivide h2 {
  font-size: 36px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 30px;
}
.subdivide .subdivide_list {
  width: 1200px;
  margin: 0 auto;
  background: #fff;
  display: flex;
  padding-left: 29px;
  padding-right: 40px;
}
.subdivide .subdivide_list .left {
  width: 142px;
  border-right: 1px solid #D6E4FF;
  padding-bottom: 45px;
}

.subdivide .subdivide_list .left .on {
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  color: #ff0610;
  line-height: 22px;
  margin-top: 45px;
  margin-bottom: 20px;
  border-right: 3px solid #ff0610;
}
.subdivide .subdivide_list .left h5 {
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  color: #000;
  line-height: 22px;
  margin-top: 70px;
  margin-bottom: 20px;
}
.subdivide .subdivide_list .right {
  width: 1055px;
  padding-left: 44px;
  padding-top: 40px;
}
.subdivide .subdivide_list .right h3 {
  font-size: 28px;
  font-weight: 600;
  color: #000000;
  line-height: 40px;
  text-align: left;
}
.subdivide .subdivide_list .right p {
  font-size: 16px;
  font-weight: 400;
  color: #4a4a4a;
  line-height: 22px;
  margin-top: 25px;
  text-align: left;
  margin-bottom: 45px;
}
.ratio {
  width: 1200px;
  margin: 40px auto 70px;
}
.ratio h2 {
  font-size: 36px;
  font-weight: 600;
  color: #000000;
  line-height: 50px;
}
.ratio img {
  width: 100%;
  margin-top: 30px;
}
</style>
